<template>
  <div class="error-page">
    <h1>{{$t("ERROR_404_HEADER")}}</h1>
    <p>{{$t("ERROR_404_CONTENT")}}</p>
    <img src="../../../assets/error-404.png" class="error-page-img" />
    <br/>
    <p>{{$t("HELPFUL_LINKS")}}</p>
    <div class="links">
      <a class="link" @click="() => router.push('/#about')">{{$t("ABOUT")}}</a>
      <a class="link" @click="() => router.push('/#features')">{{$t("FEATURES")}}</a>
      <a class="link" @click="() => router.push('/faq')">{{$t("FAQ")}}</a>
    </div>
  </div>

</template>

<script>
import { useRouter } from "vue-router"
export default {
  name: "index",
  setup() {
    const router = useRouter()
    return {
      router
    }
  }
}
</script>

<style scoped>
.error-page {
  padding: 100px 10% 0 10%;
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-page h1 {
  margin-bottom: 12px;
}
.error-page p {
  margin: 0;
}
.links {
  margin: 5px 0;
}
.link {
  color: #627DFF;
  text-decoration: underline;
  padding: 5px 20px;
  cursor: pointer;
}
.error-page-img {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .error-page-img {
    width: 90%;
  }
}
</style>